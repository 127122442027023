import cloneDeep from 'lodash.clonedeep'

export const state = () => ({
  info: {},
  pageList: [],
  storeGoods: [],
  pwaVisible: false,
  couponDialogVisible: false,
  pwaDrawerVisible: false,
  pwaPrompt: null,
  dialogVisible: false,
  tutorialEnable: false,
  orderModal: {
    visible: false,
    order: null,
    payUrl: null,
    monthCardData: null
  },
  globalVars: {},
  isChange: false,
  activity: {
    signIn: {
      sign_in_list: [],
      consecutive_gift_list: []
    },
    addUp: {
      virtual_currency_icon: '',
      add_credit_type: '',
      country_currency: '',
      virtual_currency_num: '',
      activity_stop_time: '',
      activity_level_hit_list: [],
      totalNumber: 0
    }
  },
  shopGood: null,
  awaitGoods: null
})

export const mutations = {
  SET_ACTIVITY_DATA(state, { key, data }) {
    state.activity[key] = cloneDeep(data)
  },
  SET_TUTORIAL_ENABLE(state, data) {
    state.tutorialEnable = data
  },
  SET_INFO(state, info) {
    state.info = info
  },
  SET_COUPON_DIALOG(state, data) {
    state.couponDialogVisible = data
  },
  SET_PWA_VISIBLE(state, visible) {
    state.pwaVisible = visible
  },
  SET_PAGE_LIST(state, data) {
    state.pageList = data
  },
  SET_PWA_DRAWER_VISIBLE(state, data) {
    state.pwaDrawerVisible = data
  },
  SET_PWA_PROMPT(state, data) {
    state.pwaPrompt = data
  },
  SET_GLOBAL_VARS(state, data) {
    state.globalVars = data
  },
  SET_STORE_GOODS(state, data) {
    state.storeGoods = data
  },
  SET_SHOP_GOOD(state, data) {
    state.shopGood = data
  },
  SET_DIALOG_VISIBLE(state, data) {
    state.dialogVisible = data
  },
  SET_AWAIT_GOODS(state, data) {
    state.awaitGoods = data
  },
  SET_IS_CHANGE(state, data) {
    state.isChange = data
  },
  SHOW_ORDER_MODAL(state, { code, payUrl, monthCardData }) {
    state.orderModal.visible = true
    state.orderModal.order = code
    state.orderModal.payUrl = payUrl
    state.orderModal.monthCardData = monthCardData
  },
  CLOSE_ORDER_MODAL(state) {
    state.orderModal.visible = false
    state.orderModal.order = null
    state.orderModal.payUrl = null
  }
}
