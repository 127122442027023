export function isString(obj) {
  return Object.prototype.toString.call(obj) === '[object String]'
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export function isHtmlElement(node) {
  return node && node.nodeType === Node.ELEMENT_NODE
}

export function isMobile (){
  return  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

export function isWeChat (){
  return  navigator.userAgent.toLowerCase().includes('micromessenger');
}

export function getDeviceOs() {
  const agent = navigator.userAgent.toLowerCase();
  if (/windows/.test(agent)) {
    return 'windows pc'
  } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
    return 'iphone'
  } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
    return 'ipad'
  } else if (/android/.test(agent) && /mobile/.test(agent)) {
    return 'android'
  } else if (/linux/.test(agent)) {
    return 'linux pc'
  } else if (/mac/.test(agent)) {
    return 'mac'
  } else {
    return 'other'
  }
}
