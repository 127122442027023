import {  LangName } from'~/config/cookieKeys'

export default function ({ isHMR, app, store, error, route }) {

  // 在跳转的时候因为同构渲染的关系locale可能为 ar ja等非 zh cn的字段。
  const defaultLocale = app.i18n.locale

  // If middleware is called from hot module replacement, ignore it
  if (isHMR) { return }
  // 获取编辑模式 建站器 or 预览
  const editMode = store ? store.state.editor.editMode : false
  // 获取locale  BUG:当_pid有值的时候且_lang为undefined _lang参数的值被赋值为_pid的值了 在init siteCore的时候有一层i18n的local赋值保险，暂时引不出什么bug之后想办法解决
  const routeLang = route.params.lang;
  let locale = routeLang || app.$cookies.get(LangName) || defaultLocale
  if (editMode && !store.state.locale.locales.includes(locale)) {
    locale = defaultLocale
  }
  // 设置语言
  store.commit('locale/SET_LANG', locale)
  app.i18n.locale = locale
}
