import cloneDeep from "lodash.clonedeep";
import { getLangIdI18n } from "~/utils";
import {ShopCartGoodsStatusEnum} from "~/enums/shopCartEnum";

export const state = () => ({
  shopCartList: [],
  loseShopCartList: [],
  cardList: [],
  groupList: [],
  loginCoupons: [],
  discountInfo: {}
})

export const mutations = {
  SET_SHOP_CART_LIST(state, data) {
    state.shopCartList = data.filter(item => item.goods_status === ShopCartGoodsStatusEnum.normal).map(item => {
      const newItem = {...item}
      newItem.goods_selected = newItem.goods_selected === 1 && newItem.goods_status === ShopCartGoodsStatusEnum.normal
      newItem.goods_limits = item.goods_limits === 0 ? 10 : item.goods_limits
      return newItem
    }) || []
  },
  REMOVE_LOSE_ITEM(state, index) {
    state.loseShopCartList.splice(index, 1)
  },
  SET_LOGIN_COUPONS(state, data) {
    state.loginCoupons = data
  },
  SET_LOSE_LIST(state, data) {
    state.loseShopCartList = data.filter(item => item.goods_status !== ShopCartGoodsStatusEnum.normal)
  },
  SET_DISCOUNT_INFO(state, data) {
    data.discount_level.sort((a, b) => a.discount - b.discount)
    state.discountInfo = data
  },
  SET_GROUP_LIST(state, data) {
    state.groupList = data
  },
  CLEAR_SHOP_CART(state) {
    state.shopCartList = []
    state.loseShopCartList = []
  },
  ADD_GOOD_NUM(state, id) {
    const current = state.shopCartList.find(item => item.goods_id === id)
    if (current) {
      current.goods_num = current.goods_num + 1
    } else {
      state.shopCartList.push({
        goods_id: id,
        goods_num: 1
      })
    }
  },
  UPDATE_GOOD_NUM(state, { good, number }) {
    state.shopCartList.forEach(item => {
      if (item.goods_id === good.goods_id) {
        item.goods_num = number
      }
    })
  },
  REMOVE_SHOP_CART_GOODS(state, ids) {
    ids.forEach(item => {
      const currentIndex = state.shopCartList.findIndex(good => good.goods_id === item)
      if (currentIndex > -1) {
        state.shopCartList.splice(currentIndex, 1)
      }
    })
  },
  UPDATE_ALL_GOODS_SELECT(state, checked) {
    state.shopCartList.forEach(item => {
      if (item.goods_status === ShopCartGoodsStatusEnum.normal) {
        item.goods_selected = checked
      }
    })
  },
  UPDATE_GOOD_SELECT(state, { good, checked }) {
    state.shopCartList.forEach(item => {
      if (item.goods_id === good.goods_id) {
        item.goods_selected = checked
      }
    })
  },
  SET_CARD_LIST(state, data) {
    state.cardList = data
  }
}

export const actions = {
  async getGroupList(state, context) {
    const params = {
      project_id: state.rootState.project.projectId,
      pageSize: 20,
      pageNo: 1,
      local_tz: new Date().getTimezoneOffset()
    }
    const [err, res] = await context.$utils.to(context.$api.store.getVirtualGroups(params, { editing: context.editing }))
    if (!err) {
      context.$store.commit('goods/SET_GROUP_LIST', res.data.map(item => {
        return {
          label: getLangIdI18n(item.names, context.$store.state.locale.langIdData),
          value: item.id
        }
      }))
    }
  },
  async getShopCart(state, context) {
    const params = {
      player_id: state.rootState.user.siteLoginUserInfo?.player_id,
      project_id: state.rootState.project?.projectId,
      site_id: state.rootState.user.siteLoginUserInfo?.site_id
    }
    if (!params.player_id) return
    const [err, res] = await context.$utils.to(context.$api.good.getShopCart(params))
    if (!err) {
      const data = !res.list ? [] : res.list
      if (res.discount_info?.discount_level) {
        context.$store.commit('goods/SET_DISCOUNT_INFO', cloneDeep(res.discount_info))
      }
      context.$store.commit('goods/SET_SHOP_CART_LIST', data)
      context.$store.commit('goods/SET_LOSE_LIST', data)
    }
  },
  async getCardList(state, context) {
    const params = {
      project_id: state.rootState.project?.projectId
    }
    const [err, res] = await context.$utils.to(context.$api.good.getCardList(params))
    if (!err) {
      context.$store.commit('goods/SET_CARD_LIST', res.data.map(item => {
        const newItem = {...item}
        newItem.label = item.names.langId
        newItem.value = item.id
        return newItem
      }))
    }
  }
}
