const Api =  {
  convert: '/api/v2/store/redemption/redeem',
  detail: '/api/v2/store/redemption/detail'
}
export default (request) => {
  return {
    convert: (data) => request.post(Api.convert, data),
    detail: (params) => request.get(Api.detail, { params })
  }
}
