const Api = {
  StoreActivityItems: '/api/v2/store/sale/biz/add/credit/details',
  MerchantActivityItems: '/api/v2/merchant/sale/biz/add/credit/details',
  PickActivityItem: '/api/v2/store/order/gift/create',
  GetRechargeCurrentValue: '/api/v2/store/sale/biz/quota/statistics',
  getActivityList: '/api/v2/store/sale/biz/list',
  getSign: '/api/v2/store/sale/biz/sign-in-list',
  signIn: '/api/v2/store/sale/biz/sign-in',
  getRechargeList: '/api/v2/store/sale/biz/continuous-recharge/list',
  rechargePick: '/api/v2/store/sale/biz/continuous-recharge/receive',
  lotteryDetail: '/api/v2/store/sale/biz/lottery/detail',
  lotteryDraw: '/api/v2/store/sale/biz/lottery/draw',
  historyList: '/api/v2/store/sale/biz/lottery/record-list',
  getPrize: '/api/v2/store/sale/biz/lottery/receive',
  couponList: '/api/v2/store/sale/biz/coupons/list',
  couponViewed: '/api/v2/store/sale/biz/coupons/update-view-status',
  goodsCouponList: '/api/v2/store/sale/biz/coupons/goods-coupons-list'
}

export default (request) => {
  return {
    //  活动物品列表-查询
    getActivityItems: (params, options) => {
      const { editing } = options
      // params && (params.enabled = 1)
      return request.get(
        editing ? Api.MerchantActivityItems : Api.StoreActivityItems,
        { params, ...options }
      )
    },
    //  领取活动物品
    pickActivityItems: (params) => {
      return request.post(Api.PickActivityItem, params)
    },
    getRechargeCurrentValue(params) {
      return request.get(Api.GetRechargeCurrentValue, {params})
    },
    getActivityList(params) {
      return request.get(Api.getActivityList, { params })
    },
    getRechargeList(params) {
      return request.get(Api.getRechargeList, { params })
    },
    rechargePick(data) {
      return request.post(Api.rechargePick, data)
    },
    querySignActivity(params) {
      return request.get(Api.getSign, { params })
    },
    signIn(data) {
      return request.post(Api.signIn, data)
    },
    queryLotteryDetail(params) {
      return request.get(Api.lotteryDetail, { params })
    },
    lotteryDraw(data) {
      return request.post(Api.lotteryDraw, data)
    },
    historyList(params) {
      return request.get(Api.historyList, { params })
    },
    getPrize(data) {
      return request.post(Api.getPrize, data)
    },
    couponList(params) {
      return request.get(Api.couponList, { params })
    },
    couponViewed(data) {
      return request.post(Api.couponViewed, data)
    },
    goodsCouponList(params) {
      return request.get(Api.goodsCouponList, { params })
    }
  }
}
