import { MessageEnum } from '~/enums/messageEnum'

export const SiteBuilderApi = {
  list: '/merchant/site_builder/list',
  create: '/merchant/site_builder/add',
  remove: '/merchant/site_builder/delete',
  language: '/api/v2/merchant/site/builder/desc/info', // /merchant/site_builder/desc/info
  langList: '/api/v2/merchant/site/builder/language/list', // /merchant/site_builder/language/list
  changeDefaultLang: '/api/v2/merchant/site/builder/language/change-default',
  publicInfo: '/api/v2/store/site/builder/info', // /api_customer/site_builder/info
  publicLang: '/api/v2/store/site/builder/desc/info', // /api_customer/site_builder/desc/info
  publicLangList: '/api/v2/store/site/builder/language/list', // /api_customer/site_builder/language/list
  publicPages: '/api/v2/store/site/builder/page/list', // /api_customer/site_builder/page/list
  // GetStoreUserInfo: '/api_customer/user-info',
  Logout: '/merchant/user/logout',
  SendEmail: '/merchant/user/send_email',
  GetMerchantUserInfo: '/api/v2/merchant/user/info',
  ResetPassword: '/api/v2/pay/customers/password/change',
  VerifyCode: '/api_customer/register/verification-code',
  ForgetPasswordCode: '/api/v2/pay/customers/password/code',
  pages: '/api/v2/merchant/site/builder/page/list', // /merchant/site_builder/page/list
  pageUpdate: '/api/v2/merchant/site/builder/page/edit', // /merchant/site_builder/page/edit
  createPage: '/api/v2/merchant/site/builder/page/add', // /merchant/site_builder/page/add
  Login: '/api/v2/store/login',
  Register: '/api/v2/store/register',
  GetUserInfo: '/api/v2/store/customer/info',
  UpdateUserInfo: '/api/v2/store/customer/update',
  BindPlayer: '/api/v2/store/customer/bind/player',
  LoginWithPlayerId: '/api/v2/store/login/player',
  GetUserInfoByPlayerId: '/api/v2/store/player',
  GetMultiLanguages: '/api/v2/base/site/i18n',
  userHistory: '/api/v2/store/customer/bind/history/list',
  deleteHistory: '/api/v2/store/customer/bind/history/delete'
}
export default (request) => {
  return {
    doLogout: (data) => request.get(SiteBuilderApi.Logout, data),
    resetPassword: (data) => request.post(SiteBuilderApi.ResetPassword, data),
    sendEmail: (data) => request.post(SiteBuilderApi.ForgetPasswordCode, data),
    registerSendMail: (data) => request.post(SiteBuilderApi.VerifyCode, data),
    // updateUserInfos: (data)=> request.patch(`${api.GetStoreUserInfo}`, data),
    publicInfo(params) {
      return request.get(SiteBuilderApi.publicInfo, { params })
    },
    publicLang(params) {
      return request.get(SiteBuilderApi.publicLang, { params })
    },
    publicLangList(params) {
      return request.get(SiteBuilderApi.publicLangList, { params })
    },
    publicPageList(params) {
      return request.get(SiteBuilderApi.publicPages, { params })
    },
    queryList(params) {
      return request.get(SiteBuilderApi.list, { params })
    },
    getPages(params) {
      return request.get(SiteBuilderApi.pages, { params })
    },
    createPage(data) {
      return request.post(SiteBuilderApi.createPage, data)
    },
    pageUpdate(data) {
      return request.post(SiteBuilderApi.pageUpdate, data)
    },
    langList(params, isProd) {
      return isProd
        ? request.get(SiteBuilderApi.publicLangList, { params })
        : request.get(SiteBuilderApi.langList, { params })
    },
    create(data) {
      return request.post(SiteBuilderApi.create, data, {
        headers: { 'content-type': 'application/json' },
      })
    },
    remove(data) {
      return request.post(SiteBuilderApi.remove, data)
    },
    info(params, isProd) {
      return request.get(SiteBuilderApi.publicInfo, { params })
    },
    language(params, isProd) {
      return isProd
        ? request.get(SiteBuilderApi.publicLang, { params })
        : request.get(SiteBuilderApi.language, { params })
    },
    changeDefaultLang(data) {
      return request.post(SiteBuilderApi.changeDefaultLang, data)
    },
    /*
     * 获取 建站器的支持的语言
     * */
    languageList(data) {
      return request.post(SiteBuilderApi.publicLangList, data)
    },

    /**
     * 在建站器编辑和预览的页面，会获取商户后台的用户信息，没有用户信息会直接登出
     *
     * @param {*} params
     * @returns
     */
    getMerchantUserInfo: (params) =>
      request.get(SiteBuilderApi.GetMerchantUserInfo, {
        params,
        errorMessageMode: MessageEnum.MODAL,
      }),

    // 用户账号系统 -- v2版本接口
    /**
     * 商城用户登录
     *
     * @param {*} params
     * @returns
     */
    login: (params) => request.post(SiteBuilderApi.Login, params),

    /**
     * 商城用户注册
     *
     * @param {*} data
     * @returns
     */
    registerUser: (data) => request.post(SiteBuilderApi.Register, data),

    /**
     * 查询商城用户信息
     *
     * @param {*} params
     * @returns
     */
    getUserInfo: (params) => request.get(SiteBuilderApi.GetUserInfo, { params }),

    /**
     * 更新商城用户信息
     *
     * @param {*} data
     * @returns
     */
    updateUserInfo: (data) => request.post(SiteBuilderApi.UpdateUserInfo, data),

    /**
     * 绑定游戏id
     *
     * @param {*} data
     * @returns
     */
    bindPlayer: (data) => request.post(SiteBuilderApi.BindPlayer, data),

    /**
     * 通过玩家 uid登录（游客登录）
     *
     * @param {*} params
     * @returns
     */
    loginWithPlayerId: (params) =>
      request.post(SiteBuilderApi.LoginWithPlayerId, params, {
        errorMessageMode: MessageEnum.MODAL,
      }),

    /**
     * 通过玩家游戏ID查询玩家信息
     *
     * @param {*} params
     * @returns
     */
    getUserInfoByPlayerId: (params) =>
      request.get(
        `${SiteBuilderApi.GetUserInfoByPlayerId}/${params.project_id}/${params.player_id}`
      ),
    getPlayerInfo(params) {
      return request.get(`/api/v2/store/player-info`, {params})
    },

    /**
     * 新版获取多语言
     *
     * @param {*} params
     * @returns
     */
    getMultiLanguages: (params) => request.get(
      SiteBuilderApi.GetMultiLanguages,
      { params }
    ),
    getUserAccounts(params) {
      return request.get(SiteBuilderApi.userHistory, { params })
    },
    removeUserHistory(data) {
      return request.post(SiteBuilderApi.deleteHistory, data)
    },
    getServerList(params) {
      return request.get(`/api/v2/store/servers/${params}`)
    }
  }
}
